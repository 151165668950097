<script lang="ts">
// enable fallthrough attrs
export default {
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
// note - MaybeRef is to work around issues in vuelidate error types
// this _shouldn't_ be necessary, but...?
import { type MaybeRef, get } from '@vueuse/core'
import { useAttrs } from 'vue'

export interface Props {
  modelValue: string | number | undefined
  type: string
  label?: string
  size?: string
  horizontal?: boolean
  units?: string | null
  error?: MaybeRef<string>
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  type: 'text',
  label: undefined,
  size: 'default',
  horizontal: false,
  units: '',
  error: undefined,
})

const emit = defineEmits(['update:modelValue'])

const attrs = useAttrs()
const labelFor = ref(attrs.id as string | undefined)

const value = computed({
  get() {
    return get(props.modelValue)
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

// Toggle password input to plain text input to help user entry
const passwordAsText = ref(false)
const toggleRevealPassword = () => {
  passwordAsText.value = !passwordAsText.value
}

const componentType = computed(() => {
  return props.type === 'textarea' ? 'textarea' : 'input'
})

const showUnitsBefore = computed(() => {
  return props.units === '$' || props.units === 'URL'
})

const inputSizeClass = computed(() => {
  return 'input-' + props.size
})

const inputMode = computed(() => {
  switch (props.type) {
    case 'number':
      return 'decimal'
    case 'email':
      return 'email'
    case 'tel':
      return 'tel'
    default:
      return 'text'
  }
})
</script>
<template lang="pug">
div(
  class="field-group w-full"
  :class="{ error: get(error), 'field-group--lg': size === 'lg' || size === 'xl', 'field-group--horizontal': horizontal }"
)
  label(
    v-if="label || $slots.label"
    :for="labelFor"
  )
    span(v-if="label") {{ label }}
      span(v-if="$attrs.required" class="text-state-danger ml-3") &nbsp;*
    span(v-if="$slots.label")
      slot(name="label")
      span(v-if="$attrs.required" class="text-state-danger ml-3") &nbsp;*
  div(class="w-full")
    div(v-if="componentType !== 'textarea'" class="relative flex items-stretch")
      input(
        class="input w-full relative z-1"
        :class="[inputSizeClass, { 'input-error': get(error) }]"
        v-model="value"
        v-bind="$attrs"
        :type="passwordAsText ? 'text' : props.type"
        :id="labelFor"
        :inputmode="inputMode"
      )

      div(
        v-if="units"
        class="units flex items-center bg-gray-100/60 border border-gray-200 px-15 md:px-21 text-sm font-medium text-gray-600"
        :class="showUnitsBefore ? '-order-1 border-r-0 text-base' : 'border-l-0'"
      ) {{ units }}

      UiPasswordToggle(
        v-if="props.type === 'password'"
        :is-active="passwordAsText"
        @click="toggleRevealPassword"
      )

    textarea(
      v-if="componentType === 'textarea'"
      class="input input-textarea w-full"
      :class="[{ 'input-error': get(error) }, inputSizeClass]"
      v-model="value"
      v-bind="$attrs"
      :id="labelFor"
    )

    UiError(v-if="error") {{ get(error) }}
  slot(name="extra")
</template>
